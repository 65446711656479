<template>
    <div class="container">
    </div>
</template>
<script>
import {
    login
} from '@/api/login';
export default {
    name: 'login',
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.container {

}
</style>
